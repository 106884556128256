<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('closed_periods.closed_periods') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('closed_periods.to_date') }}<span class="text-danger">*</span></label>
                <input type="date" v-model="data.to_date" class="form-control" :class="validation && validation.to_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.to_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.to_date[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('status') }}</label>
                <select name="" id="f_status" v-model="data.status" type="text" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                  <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('closed_periods.description') }}</label>
                <textarea type="text" v-model="data.description" class="form-control" :class="validation && validation.description ? 'is-invalid' : ''"></textarea>
                <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.description[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-closed-periods",
  data() {
    return {
      mainRoute: 'accounting/closed-periods',
      mainRouteDependency: 'base/dependency',


      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: {
        to_date: null,
        status: 1,
        description: null,
      },
      isEditing: false,
      validation: null,
      status_list: [],

    };
  },

  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/closed-periods');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/closed-periods');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getStatus() {
      ApiService.get(this.mainRouteDependency + "/status_open").then((response) => {
        this.status_list = response.data.data;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.to_date = response.data.data.to_date;
        this.data.description = response.data.data.description;
        this.data.status = response.data.data.status;
      });
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.closed_periods"), route: '/accounting/closed-periods'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);
    this.getStatus();

    if (this.idEdit) {
      this.getData();
    }

  },
};
</script>


